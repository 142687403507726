.user_input {
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid  #9e9d9d;
    outline: none;
    padding-left: 7px;
    width: 18rem;
    margin-bottom: 1rem;
    color: #FD125A;
  }
    
    .password_input {
      font-weight: 600;
      border-radius: 5px;
      border: 2px solid  #9e9d9d;
      outline: none;
      padding-left: 7px;
      width: 18rem;
      margin-bottom: 1rem;
      color: #FD125A;
    
    }
    .window{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      border-radius: 1rem;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
      z-index: 30;
      background-color: white;
      padding: 20px 100px;
      
    }
    .img{
      width: 30%;
      margin-bottom: 35px;
    }
    .modal_back{
      position:fixed;
      background-color: black;
      top: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      z-index: 20;
      
    }
    .buttons{
      display: flex;
      align-items: center;
      justify-content: space-between;
     }
     .telegram{
      display: flex;
      align-items: center;
     }
     .reg_title{
      font-weight: 600;
      font-size: 1.5rem;
      color: #FD125A;
     
      
     }
     .reg_title1{
        font-weight: 600;
        font-size: 1.5rem;
        color: #FD125A;
       padding-top: 2rem;
        
       }
     .add_title{
      font-weight: 600;
      font-size: 1rem;
      color: #9e9d9d;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .black_title{
      margin-bottom: 0.5rem;
    margin-top: 2rem;
      font-weight: 600;
      color: black;
      font-size: 1.3rem;
      display: flex;
      justify-content: center;
    }
    .login_form_button{
      margin-top: 2rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FD125A;
      width: 18rem;
      border: none;
      color: white;
     padding-top: 0.5rem;
     padding-bottom: 0.5rem;
      border-radius: 0.3rem;
      margin-bottom: 0.6rem;
      cursor: pointer;
   
    }
    .login_form_button:hover{
        opacity: 0.5;
    }
    .login_form_button1{
        border: 1px solid #FD125A;
        margin-top: 2rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 18rem;
        font-size: 1rem;
        color: #FD125A;
       padding-top: 0.5rem;
       padding-bottom: 0.5rem;
        border-radius: 0.3rem;
        margin-bottom: 0.6rem;
        cursor: pointer;
     
      }
      .login_form_button1:hover{
        background-color: #FD125A;
        color: white;
      }

    .close_img{
      cursor: pointer;
      position: absolute;
      right: 0%;
      padding: 1rem;
    }
  .data_operations{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
  }
  .balance_sec{
    display: block;
  }
  .black_title1{
  margin-top: 1rem;
    font-weight: 600;
    color: black;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .balance{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
   color: black;
  }
  .time{
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }  
  .time_item{
    color: #FD125A;
    margin-left: 1.5rem;
  }
  
  @media(max-width:1200px)
{

  .window{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 0;
    top: 0;
   transform: translate(-0%, -0%);
    z-index: 30;
    background-color: white;
    padding: 20px 30px;
    width: 100%;
    left: 0;
    
  }

}
     
    
  