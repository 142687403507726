*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.catalog{
    width: 900px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.goods{
    background-color: rgb(59, 59, 58);
    margin-top: 1%;
    padding: 3%;
    border-radius: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 4px solid rgb(255, 0, 0);
    

}
.goods_item{
    background-color: black;
    width: 400px;
    height: 250px;
    border-radius: 40px 40px 0px 0px;
    background: url('../../images/devilmusthave.jpg');
   
    background-size: cover;
    
   
}
.product_description{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
    background-color: rgb(49, 49, 49);
    border-radius: 0px 0px 40px 40px;
    padding: 5px;
}

.product_title{
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 12px;
  
}

.price{
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 12px;
}
.conn{

    display: flex;
    align-items: center;
    justify-content: center;
}

.operations{
    display: flex;
   justify-content: space-between;
    width: 100%;
    padding-top: 2rem;
}

.categories{
    width: 80%;
    height: 5vh;
    border-radius: 6px;
    align-items: center;
    display: flex;
    
    font-weight: 600;
}
.loop{
   padding: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #FD125A;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
}
.main_find{
    display: flex;
    align-items: center;
    width: 100%;
    
}
.category_item{
    color: #C0C0C0;
    width: 22%;
    background-color: white;
    height: 4vh;
    display: flex;
    align-items: center;    
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    padding: 1rem;
   cursor: pointer;
 }
 .arrow{
    margin-left: 1rem;
    transition: all 0.1s linear;
 }
 .arrow_active{
    margin-left: 1rem;
    transform: rotate(-180deg);
    transition: all 0.1s linear;
 }
.find{
    background-color: #fff;
     width: 90%;
     
    height: 4vh;
    margin-left: 1rem;
    border: none;
    padding:1rem;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
     outline: none;
     background-color: white;
}

.top_catalog{
    display: grid;
    grid-template-columns: 57% 14% 15%;
    align-content: center;
    margin-top: 2rem;
}
.top_catalog_item1{
    color: #C0C0C0;
    font-weight: 600;
   
    
}
.top_catalog_item2{
    color: #C0C0C0;
    font-weight: 600;
    text-align: center;
  
}
.top_catalog_item3{
    color: #C0C0C0;
    font-weight: 600;
    text-align: center;
}
.find::placeholder{
    color: #C0C0C0;
    font-weight: 600;
}


.tik_tok{
    margin-top: 1.25rem;
    max-width: 100%;
    background-color: black;
    height: 3.5rem;
    border-radius: 10px;
    padding: 17px;
    display: flex;
  
}

.tik_tok_title{
    color: #fff;
    display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  margin-right: 25px;
}
.ctg_item{
    margin-top: 1.25rem;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    padding-left: 0.5rem;
     padding: 1rem 1rem 1rem 0.5rem;
     
    display: grid;
    grid-template-columns: 5% 60% 12% 9% 15%;
   align-items: center;

}
.item_title{
    font-weight: 600;
    margin-left: 1rem;
}

.buy{
    border: 1.23214px solid #FD125A;
    color: #FD125A;
    background-color: #fff;
    padding: 1rem;
    border-radius: 6px;
    
}
.buy1{
    border: 1.23214px solid #FD125A;
    color: #FD125A;
    background-color: #fff;
    padding: 1rem 2.7rem 1rem 2.7rem;
    border-radius: 6px;
    margin-left: 2rem;
    margin-right: 1rem;
}
.buy:hover{
    color: #fff;
    background-color: #FD125A;
}
.show_more_tt{
    font-weight: 600;
    margin-top: 1.25rem;
    width: 100%;
    border-radius: 10px;
    color: black;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;

}
.show_more_tt:hover{
   color: #fff;
   background-color: black;

}
.show_more_yt{
    font-weight: 600;
    margin-top: 1.25rem;
    width: 100%;
    border-radius: 10px;
    color: red;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
}
.show_more_yt:hover{
    color: #fff;
    background-color: red;
}

.price_item{
    color: #FD125A;
    font-weight: 600;
}

.yt{
    margin-top: 1.25rem;
    max-width: 100%;
    background-color: red;
    height: 3.5rem;
    border-radius: 10px;
    padding: 17px;
    display: flex;
}
.spt{
    margin-top: 1.25rem;
    max-width: 100%;
    background-color: #1ED760;
    height: 57px;
    border-radius: 10px;
    padding: 17px;
    display: flex;
}
.yt_title{
    color: #fff;
    display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  margin-right: 25px;
}
.show_more_spt{
    font-weight: 600;
    margin-top: 1.25rem;
    width: 100%;
    border-radius: 10px;
    color:  #1ED760;
    border: 1px solid  #1ED760;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
}
.show_more_spt:hover{
    color: #fff;
    background-color:  #1ED760;
}
.page_num{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

}
.pro{
    color: white;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0%;
    right: 40%;
}
.page_num_inner{
    width: 20%;
    display: flex;
    justify-content: space-between;
}
.page_num_item{
    color: #9e9d9d;
    background-color:#F3F3F3;
    border: 2px solid #9e9d9d;
    padding: 0.5rem 0.7rem 0.5rem 0.7rem;
    border-radius: 3px;
}
.page_num_item:hover{
    color: white;
    background-color: #FD125A;
    border: 2px solid #FD125A;
}

.goods_item:last-child{
    margin-right: 0px;
   
}
.love{ 
    max-width: 100%;
    margin-top: 3rem;
    height: 17.5rem;
    background-color: #FD125A;
    border-radius: 13px;
    position: relative;
}
.love_item1{
    font-weight: 600;
    font-size: 2.25rem;
    color: #FFFFFF;
    margin-right: 1rem;
    
}
.love_item2{
    font-weight: 600;
    font-size: 1.5rem;
    color: #FFFFFF;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
}

.love_item3{
    font-family: "SF_Pro_B";
    font-weight: 700;
    font-size: 1.5rem;
    background-color: #fff;
    color: #FD125A;
    border: none;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border-radius: 0.2rem;
    
}

.love_desc{
    padding: 3rem;
    display: block;
   width: 43rem;
   height: 11.25rem;
}
.aboba{
    display: flex;
}

.love_img{
    position:absolute;
   right: 0%;
   top: 19%;

    
}
.devilmobile{
    display: none;
}
.reset{
    border: 1.23214px solid #FD125A;
    color: #FD125A;
    background-color: #fff;
    padding: 0.8rem 2.1rem 0.8rem 2.1rem;
    border-radius: 6px;
    margin-right: 0.5rem;
    margin-left: 2rem;
    cursor: pointer;
 
    

}
.buy{
    border: 1.23214px solid #FD125A;
    color: #FD125A;
    background-color: #fff;
    padding: 13px;
    border-radius: 6px;
    
}
.reset:hover{
    color: #fff;
    background-color: #FD125A;
}
.heart1{
    display: none;
}
.select_item{
    cursor: "pointer"; 
    padding: 5px; 
    color: #fff; 
    font-weight: 500; 
    text-align: center; 
    border-radius: 4px;
}
.select_item:last-child{
    margin-bottom: 0;
}

@media(max-width:1200px) {
   
    .catalog{
        padding-left: 0;
        padding-right: 0;
        padding: 1rem;
    }
    .reset{
        border: 1.23214px solid #FD125A;
        color: #FD125A;
        background-color: #fff;
        padding: 0.8rem 2.1rem 0.8rem 2.1rem;
        border-radius: 6px;
        margin-right: 1rem;
        margin-left: 1rem;
     
        
    
    }
    .operations{
        display: block;
       
    }
    .categories{
        display: block;
        
    }
    .category_item{
        width: 50%;
    }
    .find{
        margin-left: 0;
       
    }
    .aboba{
        display: flex;
        align-items: center;

    }
    .heart1{
        display: block;
       
    }
   
   
   
    .top_catalog{
        display: grid;
        grid-template-columns: 42% 22% 15%;
        align-content: center;
        margin-top: 6rem;
    }
   
    .top_catalog_item1{
        font-size: 0.875rem;
        
    }
    .top_catalog_item2{
        font-size: 0.875rem;
        
    }
    
    .top_catalog_item3{
        font-size: 0.875rem;
        
    }
    .tik_tok_title{
        color: #fff;
        display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1rem;
      margin-right: 0.5rem;
    }
    .yt_title{
        color: #fff;
        display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1rem;
      margin-right: 0.5rem;
    }
    .item_title{
        font-size: 0.8rem;
        font-weight: 600;
        margin-left: 1.25rem;
    }
    .page_num_inner{
        width: auto;
    }
    .item_price{
        font-size: 0.8rem;
    }
    .item_amount{
        font-size: 0.8rem;
    }
    .buy{
        border: 1.23214px solid #FD125A;
        color: #FD125A;
        background-color: #fff;
        padding: 0.1rem;
        border-radius: 6px;
        
    }
    .ctg_item{
       
        grid-template-columns: 5% 50% 18% 12% 18%;
    
    }
    .loop{
        width: 2rem;
        padding: 1rem;
        height: 2.5rem;
        align-items: center;
       display: flex;
       justify-content: center;
        background-color: #FD125A;
        border-radius: 0rem 0.5rem 0.5rem 0rem;
        margin-top: 1rem;
       
    }
    .love_img{
        display: none;
    }
   
    .love{ 
        max-width: 100%;
        margin-top: 3rem;
        height: 8rem;
        background-color: #FD125A;
        border-radius: 13px;
        position: relative;
    }
    .love_item1{
        font-weight: 600;
        font-size: 1rem;
        color: #FFFFFF;
        margin-right:  0.5rem;
        margin-bottom:  0.5rem;
        display: flex;
        align-items: center;
        
    }
    .love_item2{
        font-weight: 600;
        font-size: 1rem;
        color: #FFFFFF;
        margin-top: 0.5rem;
        margin-bottom:  1rem;

        
    }
    
    .love_item3{
        font-size: 1rem;
        background-color: #fff;
        color: #FD125A;
        border: none;
        padding: 0.3rem 1rem 0.3rem 1rem;
        border-radius: 0.2rem;
        
    }
    .heart{
        display: none;
    }
    
    .love_desc{
        padding: 1rem;
       
       width: 43rem;
       height: 11.25rem;
    }
    .devilmobile{
        display: block;
        position: absolute;
        right: 0%;
        top:27%;
    }
    .pro{
        color: white;
        font-size: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0%;
        right: 30%;
    }
    .reset{
        margin-left: 3rem;
        margin-top: 1rem;
        padding: 0.5rem;
    }
    .main_find{
        display: flex;
       align-items: center;
        
    }
   
    .find{
        background-color: #fff;
         width: 10rem;
        height: 2.5rem;
        border: none;
        padding:1rem;
        border-radius: 0.5rem 0rem 0rem 0.5rem;
         outline: none;
         margin-top: 1rem;
    }
    
       
    
    }
