.user_input {
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid  #9e9d9d;
    outline: none;
    padding-left: 7px;
    width: 18rem;
    margin-bottom: 1rem;
    color: #FD125A;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
    
    .password_input {
      font-weight: 600;
      border-radius: 5px;
      border: 2px solid  #9e9d9d;
      outline: none;
      padding-left: 7px;
      width: 18rem;
      margin-bottom: 1rem;
      color: #FD125A;
     
    
    }
    .window{
      text-align: center;
      padding: 20px 100px;
      display: block;
      flex-direction: column;
      align-items: center;
      position: absolute;
      border-radius: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 30;
      background-color: white;
      
      
    }
    .img{
      width: 30%;
      margin-bottom: 35px;
    }
    .modal_back{
      position:fixed;
      background-color: black;
      top: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      z-index: 20;
      
    }
    .buttons{
      display: flex;
      align-items: center;
      justify-content: space-between;
     }
     .telegram{
      display: flex;
      align-items: center;
     }
     .reg_title{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 1.5rem;
      color: #FD125A;
      margin-bottom: 0.5rem;
      padding-top: 2rem;
     }
     .add_title{
      font-weight: 600;
      font-size: 1rem;
      color: #9e9d9d;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .black_title{
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: black;
      font-size: 1.3rem;
      display: flex;
      justify-content: center;
    }
    .login_form_button{
      cursor: pointer;
      margin-top: 2rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FD125A;
      width: 18rem;
      border: none;
      color: white;
     padding-top: 0.5rem;
     padding-bottom: 0.5rem;
      border-radius: 0.3rem;
      margin-bottom: 0.6rem;
    }
    

    .login_form_button span{
      color: #fff;
    }
    .close_img{
      cursor: pointer;
      position: absolute;
      right: 0%;
      padding: 1rem;
    }
  .data_operations{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
  }
  .balance_sec{
    display: block;
  }
  .black_title1{
  margin-top: 2rem;
    font-weight: 600;
    color: black;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .balance{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
   color: #FD125A;
  }

  
  .plata{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .plata_container{
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .plata_container:last-child{
    margin-right: 0;
  }
  .plata_container div{
    margin: 5px 0;
  }
  .plata_img{
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #9e9d9d;
    width: 135px;
    height: 135px;
  }
  .plata_img:hover{
    border: 1px solid #FD125A;
  
  }
  
  .underText{
    font-weight: 600;
    font-size: 1rem;
    margin: 10px 0;
  }
  .lava{
    width: 15vh;
    height: 15vh;
  }
  .cardlink{
    width: 1vh;
    height: 1vh;
  }
  .wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .plata_titles{
    font-weight: 600;
    font-size: 1rem;
    display: grid;
    grid-template-columns: 28% 25% 28% 25%;
  }
  .img_label{
    font-family: "SF_Pro_B";
    font-weight: 700;
  }

  .description{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 16px;
    column-gap: 8px;
  }
  .description span{
    font-size: 18px;
    font-weight: 600;
  }
  .payment_info{
    margin: 10px 0;
    font-size: 18px;
    font-weight: 600;
    color: #FD125A;
  }
  @media(max-width:1200px)
{
  .description{
    font-size: 12px;
  }
  
  .window{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 0;
    top: 0;
    transform: translate(-0%, -0%);
    z-index: 30;
    background-color: white;
    padding: 20px 30px;
    width: 100%;
    left: 0;
    
  }
  .plata_item1{
    border-radius: 6px;
    border: 1px solid #9e9d9d;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  
  }
  .plata{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .plata_container{
    margin-right: 0;
  }
  .plata_titles{
    display: none;
  }
  .plata_item2{
    padding: 2rem;
    border: 1px solid #9e9d9d;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  
  }
  .plata_item3{
    border: 1px solid #9e9d9d;
    border-radius: 6px;
    padding: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  
  }
  .plata_item4{
    border: 1px solid #9e9d9d;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center; 
    border-radius: 6px;
    margin-bottom: 1rem;
  
  }
}
     
    
  