* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
}

li,
a,
button {
    text-decoration: none;
    font-weight: 500;
}

.logo {
    cursor: pointer;
}

.logo1 {
    font-style: italic;
    font-weight: 700;
    font-size: 2rem;
    position: relative;
    color: #FD125A;
    position: relative;
}

.logo2 {
font-style: italic;
font-weight: 700;
font-size: 1.5rem;
color: #000000;
}

.column2 {
   max-width: 100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
   
}

.column3 {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.promo {
    font-weight: 600;
    max-width: 100%;
    height: 2.625rem;
    display: flex;
    background: #FD125A;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1320px;
    padding: 0 10px;
    margin: 0 auto;
}
.mobile_profile{
    display: none;
}



.nav__links a {
    transition: all 0.3 ease 0s;
}


.nav__link_desk {
   cursor: pointer;
    background-color: inherit;
    font-weight: 400;
    border: none;
    color: rgb(0, 0, 0);
    position: relative;
    font-size: 20px;
    text-align: center;
    margin-right: 4rem;
    margin-left: 4rem;
}
.nav__link_desk:hover{
    color: #FD125A;
}
.nav__link {
   cursor: pointer;
    background-color: inherit;
    font-weight: 400;
    border: none;
    color: rgb(0, 0, 0);
    position: relative;
    font-size: 20px;
    text-align: center;
    margin-right: 4rem;
    margin-left: 4rem;
}



.nav__link:hover {
    color: #FD125A;
}




.registration {
    padding-top: 1%;
    display: flex;
}

.sign_up {
    font-weight: 400;
    font-size: 16px;
    padding: 10px 1rem;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #FD125A;
    color: #fff;

}

.sign_in {
    font-weight: 400;
    font-size: 16px;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #F3F3F3;
    transition: all 0.2s;
}
.sign_in:hover{
    color: #FD125A;
    transition: all 0.2s;
}

.admin {
    color: rgb(0, 0, 0);
    padding: 5px 15px;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: linear 0.25s;
    font-size: 1.25rem;
}
.header_burger{
    display: none;
}

.admin:hover {
    background-color: rgb(245, 29, 29);
    color: #fff;
}



.header_avatar_and_button {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.sign_up_1 {
    color: rgb(0, 0, 0);
    padding: 5px 15px;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    cursor: pointer;
    transition: linear 0.25s;
    font-size: 1.25rem;

}

.sign_up_1:hover {
    background-color: rgb(245, 29, 29);
}


.balance_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
}

.balance_name span {
    font-size: 20px;
    margin-bottom: 7px;
}
.devilback{
    position: absolute;
    top: 25%;
}
.devilback1{
    position: absolute;
    top: 25%;
    right: 0;
    
}
.dev2{
    position: absolute;
    top: 60%;
    left: 7%;
}
.dev2_2{
    position: absolute;
    top: 60%;
    right: 7%;
    transform: scaleX(-1);
}

.pls{
    position: absolute;
    top: 8%;
}

.pl1{
    
   margin-right: 0.5rem;
}
.column54{
    display: none;
    flex-direction: column;
}
.user_balance{
    color: #FD125A;
    display: flex;
    justify-content: center;
    font-weight: 600;
}
.user_balancemain{
    font-weight: 500;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
}
.poplnit{
    font-weight: 500;
    cursor: pointer;
    color: #FD125A;
    border: 1px #FD125A solid;
    padding: 0.2rem 0.8rem;
    text-align: center;
    border-radius: 5px;
    font-size: 1rem;
}
.poplnit1{
    cursor: pointer;
    color: #FD125A;
    border: 1px #FD125A solid;
    padding: 1rem;
    text-align: center;
    border-radius: 5px;

}

@media(max-width:1200px) {
   
.promo{
    display: none;
}

.header{ 
    width: 100%;
padding: 1rem;
}
.pls{
    position: absolute;
    top: 1%;
}
.devilback{
    position: absolute;
    top: 25%;
    display: none;
}
.devilback1{
    position: absolute;
    top: 25%;
    right: 0;
    display: none;
}
.dev2{
    position: absolute;
    top: 60%;
    left: 7%;
    display: none;
}
.dev2_2{
    position: absolute;
    top: 60%;
    right: 7%;
    transform: scaleX(-1);
    display: none;  
}
.nav__link_desk{
display: none;
}
.column3{
    display: none;
}
.header_burger{
    display: block;
    transition: all 0.1s linear;
    
}
.header_burger_active{
    display: block;
    transform: rotate(-90deg);
    transition: all 0.1s linear;

}

.column54{
   display: grid;
   
}
.knopki{
    display: grid;
    padding: 2rem 4rem 0;
}
.nav__links{
    display: grid;
}
.nav__link{
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.sign_up{
    margin-bottom: 1rem;
    color: #fff;
    background-color:#FD125A;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1.25rem;
  

}
.devilprofile{
    display: none;
}
.user_balance{
    color: #FD125A;
    display: flex;
    justify-content: center;

    font-weight: 600;
}
.user_balancemain{
    
    font-weight: 500;
    font-size: 1rem;
  
}
.mobile_profile{
    display: flex;
    align-items: center;
    
}


   

}