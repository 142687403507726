*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.catalog{
  max-width: 100%;
  padding-left: 31rem;
  padding-right: 31rem;
    
}

.goods{
    background-color: rgb(59, 59, 58);
    margin-top: 1%;
    padding: 3%;
    border-radius: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 4px solid rgb(255, 0, 0);
    

}
.goods_item{
    background-color: black;
    width: 400px;
    height: 250px;
    border-radius: 40px 40px 0px 0px;
    background: url('../../../images/devilmusthave.jpg');
   
    background-size: cover;
    
   
}
.product_description{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
    background-color: rgb(49, 49, 49);
    border-radius: 0px 0px 40px 40px;
    padding: 5px;
}

.product_title{
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 12px;
  
}

.price{
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 12px;
}

.operations{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
}

.categories{
    width: 40%;
    height: 5vh;
    background-color: #fff;
    border-radius: 6px;
    align-items: center;
   
    display: flex;
    padding-left: 1rem;
    font-weight: 600;
}

.find{
    background-color: #fff;
     width: 60%;
    height: 5vh;
    margin-right: 1rem;
    margin-left: 1rem;
    border: none;
    padding:1rem;
    border-radius: 6px;
     outline: none;
}
.main_find{
    display: flex;
}
.top_catalog{
    display: grid;
    grid-template-columns: 62% 13% 15%;
    align-content: center;
    margin-top: 2rem;
}
.top_catalog_item1{
    color: #C0C0C0;
    font-weight: 600;
   
    
}
.top_catalog_item2{
    color: #C0C0C0;
    font-weight: 600;
  
}
.top_catalog_item3{
    color: #C0C0C0;
    font-weight: 600;
}
.find::placeholder{
    color: #C0C0C0;
    font-weight: 600;
}

.category_item{
   color: #C0C0C0;
  
}
.tik_tok{
    margin-top: 1.25rem;
    max-width: 100%;
    background-color: black;
    height: 3.5rem;
    border-radius: 10px;
    padding: 17px;
    display: flex;
  
}

.tik_tok_title{
    color: #fff;
    display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  margin-right: 25px;
}
.ctg_item{
    margin-top: 1.25rem;
    background-color: #fff;
    
    border-radius: 10px;
     padding: 0 1rem 0 25px;
     
    display: grid;
    grid-template-columns: 3% 57% 10% 15% 15%;
   align-items: center;

}


.item_title{
    font-family: "SF_Pro";
    padding: 1.5rem 0;
    font-weight: 600;
    font-size: 20px;
    padding-left: 0.8rem;
    cursor: pointer;
}
.item_description{
    margin-left: 1rem;
    padding-bottom: 1rem;
    grid-column-start: 1;
    grid-column-end: 6;
}
.buy{
    font-family: "SF_Pro";
    cursor: pointer;
    font-weight: 600;
    min-width: 75px;
    border: 1.23214px solid #FD125A;
    color: #FD125A;
    background-color: #fff;
    padding: 13px;
    border-radius: 6px;
    
}
.buy:hover{
    color: #fff;
    background-color: #FD125A;
}
.show_more_tt{
    font-weight: 600;
    margin-top: 1.25rem;
    width: 100%;
    border-radius: 10px;
    color: black;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;

}
.show_more_tt:hover{
   color: #fff;
   background-color: black;

}
.show_more_yt{
    font-weight: 600;
    margin-top: 1.25rem;
    width: 100%;
    border-radius: 10px;
    color: red;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
}
.show_more_yt:hover{
    color: #fff;
    background-color: red;
}

.price_item{
    color: #FD125A;
    font-weight: 600;
}

.yt{
    margin-top: 1.25rem;
    max-width: 100%;
    background-color: red;
    height: 3.5rem;
    border-radius: 10px;
    padding: 17px;
    display: flex;
}
.spt{
    margin-top: 1.25rem;
    max-width: 100%;
    background-color: #1ED760;
    height: 57px;
    border-radius: 10px;
    padding: 17px;
    display: flex;
}
.yt_title{
    color: #fff;
    display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  margin-right: 25px;
}
.show_more_spt{
    font-weight: 600;
    margin-top: 1.25rem;
    width: 100%;
    border-radius: 10px;
    color:  #1ED760;
    border: 1px solid  #1ED760;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
}
.show_more_spt:hover{
    color: #fff;
    background-color:  #1ED760;
}
.page_num{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

}
.page_num_inner{
    width: 20%;
    display: flex;
    justify-content: space-between;
}
.page_num_item{
    color: #9e9d9d;
    background-color:#F3F3F3;
    border: 2px solid #9e9d9d;
    padding: 0.5rem 0.7rem 0.5rem 0.7rem;
    border-radius: 3px;
}
.page_num_item:hover{
    color: white;
    background-color: #FD125A;
    border: 2px solid #FD125A;
}

.goods_item:last-child{
    margin-right: 0px;
   
}
.love{ 
    max-width: 100%;
    margin-top: 3rem;
    height: 17.5rem;
    background-color: #FD125A;
    border-radius: 13px;
    position: relative;
}
.love_item1{
    font-weight: 600;
    font-size: 2.25rem;
    color: #FFFFFF;
    margin-right: 1rem;
    
}
.love_item2{
    font-weight: 600;
    font-size: 1.5rem;
    color: #FFFFFF;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
}

.love_item3{
    font-size: 1.5rem;
    background-color: #fff;
    color: #FD125A;
    border: none;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border-radius: 0.2rem;
    
}

.love_desc{
    padding: 3rem;
    display: block;
   width: 43rem;
   height: 11.25rem;
}
.aboba{
    display: flex;
}

.love_img{
    position:absolute;
   right: 0%;
   top: 19%;

    
}
.tiktok_img{
    padding: 1rem 0;
    width: 25px;
    height: auto;
    cursor: pointer;
}
.item_amount{
    font-family: "SF_Pro";
    text-align: center;
}
.item_price{
    font-family: "SF_Pro";
    text-align: center;
}
.readFull{
    border-radius: 0.3rem;
    background-color: #fff;
    border: 1.23214px solid #FD125A;
    padding: 0.4rem 0.8rem;
    margin-top: 1rem;
    cursor: pointer;
    
    font-family: "SF_Pro";
    font-size: 0.9rem;
    color: #FD125A;
    transition: all 0.2s;
}
.fullLink{
    font-weight: 600;
    text-decoration: none;
    color: #FD125A;
    
}
.readFull:hover{
    border: 1px dashed;
    transition: all 0.2s;
}


@media(max-width:1200px) {
   
    .catalog{
        padding-left: 0;
        padding-right: 0;
        padding: 1rem;
    }
    .operations{
        display: block;
       
    }
    .top_catalog{
        display: grid;
        grid-template-columns: 45% 25% 15%;
        align-content: center;
        margin-top: 2rem;
    }
    .find{
        margin-top: 1rem;
        margin-right: 0rem;
        margin-left: 0rem;
    }
    .top_catalog_item1{
        font-size: 0.875rem;
        
    }
    .top_catalog_item2{
        font-size: 0.875rem;
        
    }
    
    .top_catalog_item3{
        font-size: 0.875rem;
        
    }
    .tik_tok_title{
        color: #fff;
        display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1rem;
      margin-right: 0.5rem;
    }
    .yt_title{
        color: #fff;
        display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1rem;
      margin-right: 0.5rem;
    }
    .item_title{
        font-size: 0.8rem;
        font-weight: 600;
        margin-left: 1.25rem;
    }
    .page_num_inner{
        width: auto;
    }
    .item_price{
        font-size: 0.8rem;
    }
    .item_amount{
        font-size: 0.8rem;
    }
    .buy{
        width: 20px;
        border: 1.23214px solid #FD125A;
        color: #FD125A;
        background-color: #fff;
        padding: 0.5rem;
        border-radius: 6px;
        
    }
    .ctg_item{
       width: 100%;
        grid-template-columns: 1% 47% 14% 16% 25%;
    
    }
    .item_description{
        grid-column-start: 1;
        grid-column-end: 6;
    }
    
    

       
    
    }
