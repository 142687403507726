.container{
    display: none;
    padding: 20px 10%;
    justify-content: space-around;
}
.con{
 display: grid;
 grid-template-columns: 0.8fr 4fr;
}
.menu{
    height: 100vh;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.top{
    width: 100%;
    height: 10vh;
    background-color: white;
     display: flex;
     align-items: center;
     justify-content: end;
    
}
.main{
    background-color: #F3F3F3;
 
    height: 90vh;
    
   
}

.buy{
    border: 1.23214px solid #FD125A;
    color: #FD125A;
    background-color: #fff;
    padding: 0.5rem 3rem 0.5rem 3rem;
    border-radius: 6px;
   
    
}
.buy:hover{
    color: #fff;
    background-color: #FD125A;
}
.padding{
    width: 5%;
}
.logo1 {
    padding-top: 1rem;
    font-style: italic;
    font-weight: 700;
    font-size: 2rem;
    
    color: #FD125A;
}

.logo2 {
font-style: italic;
font-weight: 700;
font-size: 1.5rem;
color: #000000;
}
.ctg_sec{
    display: flex;
    align-items: center;
    justify-content: center;
   margin-top: 50%;
}
.ctg_title{
    color: #BBBCC6;
    font-weight: 600;
    font-size: 1.5rem;
    margin-left: 0.7rem;
}
.ctg_title:hover{
    color: #FD125A;
    
}
.user_sec{
  
    margin-top: 20%;
    display: flex;
}
.user_sec_img{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.category_add{
    padding: 5%;

}
.footer_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background-color:#FD125A;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1.25rem;
}
.plus{
  
    margin-left: 2rem;
    font-size: 1.5rem;
   
   
}

.footer_btn:hover{
    opacity: 0.5;
}
.ctgs{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    
}
.ctg_item_add1{
    background-color:red;
    margin-right: 1rem;
    border-radius: 5px;
    color: white;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1rem;
    

}
.ctg_item_add2{
    background-color:rgb(8, 200, 8);
    margin-right: 1rem;
    border-radius: 5px;
    color: white;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1rem;

}
.ctg_item_add3{
    background-color:black;
     color: white;
    border-radius: 5px;
    margin-right: 1rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1rem;

}
.ctg_item_add4{
    background-color:rgb(75, 75, 215);
     color: white;
    border-radius: 5px;
    margin-right: 1rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1rem;

}
.ctg_item_add5{
    
    background-color:rgb(31, 45, 90);
     color: white;
    border-radius: 5px;
    margin-right: 1rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1rem;

}
.ctg_item_add6{
    margin-top: 1rem;
    background-color:rgb(50, 52, 59);
     color: white;
    border-radius: 5px;
    margin-right: 1rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1rem;

}
.window{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 1rem;
     left: 33%;
    top: 10%;
    z-index: 30;
    background-color: white;
    height: 50vh;
    width: 70vh;
    position: fixed;
  }
  .close_img{
    position: absolute;
    right: 0%;
    padding: 1rem;
  }
  .reg_title{
    font-weight: 600;
    font-size: 1.5rem;
    color: #FD125A;
    margin-bottom: 0.5rem;
    padding-top: 2rem;
   }
   .add_title{
    font-weight: 600;
    font-size: 1.2rem;
    color: #9e9d9d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user_input {
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid  #9e9d9d;
    outline: none;
    padding-left: 7px;
    width: 18rem;
    margin-bottom: 1rem;
    color: #FD125A;
  }
    
    .password_input {
      font-weight: 600;
      border-radius: 5px;
      border: 2px solid  #9e9d9d;
      outline: none;
      padding-left: 7px;
      width: 18rem;
      margin-bottom: 1rem;
      color: #FD125A;
    
    }
    .black_title{
        margin-bottom: 0.5rem;
      margin-top: 1.5rem;
        font-weight: 600;
        color: black;
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
      }
      .login_form_button{
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FD125A;
        width: 18rem;
        border: none;
        color: white;
       padding-top: 0.5rem;
       padding-bottom: 0.5rem;
        border-radius: 0.3rem;
        margin-bottom: 0.6rem;
      }
      .login_form_button:hover{
        opacity: 0.5;
      }
