@font-face {
  font-family: "Gilroy";
  src: url('./fonts/Gilroy/gilroy-regular.ttf') format('truetype');
}
@font-face {
  font-family: "SF_Pro";
  src: url('./fonts/Gilroy/SFProText/SFProText-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "SF_Pro_B";
  src: url('./fonts/Gilroy/SFProText/SFProText-Bold.ttf') format('truetype');
}
body {
  font-family: "Gilroy";
background-image: url(../src/images/bakckbakbkak.png);
background-size: cover;
background-repeat: repeat-y;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media(max-width:1200px)
{
 body{
  background-color: #f3f3f3;
  background-image: none;
 }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

