.black_title{
    margin-bottom: 1rem;
  margin-top: 2rem;
    font-weight: 600;
    color: black;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .password_input {
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid  #9e9d9d;
    outline: none;
    padding-left: 7px;
    width: 18rem;
    margin-bottom: 1rem;
    color: #FD125A;
  
  }
  .password_input:focus{
    border: 1px #FD125A solid;
    box-shadow: none;
  }
  .password_input::placeholder{
    font-weight: 400;
  }
  .window{
  display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
    background-color: white;
    padding: 1.2rem 7rem;
  }
  .reg_title{
    font-weight: 600;
    font-size: 1.5rem;
    color: #FD125A;
    margin-bottom: 0.5rem;
    padding-top: 2rem;
    text-align: center;
   }
   .add_title{
    font-weight: 600;
    font-size: 1rem;
    color: #9e9d9d;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .login_form_button{
    cursor: pointer;
    margin-top: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FD125A;
    width: 18rem;
    border: none;
    color: white;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 0.6rem;
  }
  .close_img{
    cursor: pointer;
    position: absolute;
    right: 0%;
    padding: 1rem;
  }
  .modal_back{
    position:fixed;
    background-color: black;
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 20;
    
  }
  .link{
    margin: 10px 0 ;
    text-decoration: none;
    color: inherit;
  }
  .link:hover{
    color: #FD125A;
    transition: color 0.1s;
  }
  .login_form_button:hover{
    opacity: 0.5;
  }
  @media(max-width:1200px)
  {
  
    .window{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      border-radius: 0;
      top: 0;
      transform: translate(-0%, -0%);
      z-index: 30;
      background-color: white;
      padding: 20px 30px;
      width: 100%;
      left: 0;
      
    }
      
    }
  
  
  