
.user_input {
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid  #9e9d9d;
  outline: none;
  padding-left: 7px;
  width: 18rem;
  margin-bottom: 1rem;
  color: #FD125A;
}
.user_input:focus{
  border: 1px solid  #FD125A;
  box-shadow: none;
}
.user_input::placeholder{
  font-weight: 400;
}
  .password_input {
    border-radius: 5px;
    outline: none;
    border: 1px solid  #9e9d9d;
    padding-left: 7px;
    width: 18rem;
    margin-bottom: 1rem;
    color: #FD125A;
  
  }
  .window{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 1rem;
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    z-index: 30;
    background-color: white;
    padding: 1.2rem 12rem;
    
  }
  .img{
    width: 30%;
    margin-bottom: 35px;
  }
  .modal_back{
    position:fixed;
    background-color: black;
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 20;
    
  }
  .buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
   }
   .telegram{
    display: flex;
    align-items: center;
   }
   .reg_title{
    font-weight: 600;
    font-size: 1.5rem;
    color: #FD125A;
    margin-bottom: 0.5rem;
    padding-top: 2rem;
   }
   .add_title{
    font-weight: 600;
    font-size: 1rem;
    color: #D7D7D7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .black_title{
    margin-bottom: 0.5rem;
  margin-top: 2rem;
    font-weight: 600;
    color: black;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
  }
  .login_form_button1{
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FD125A;
    width: 18rem;
    border: none;
    color: white;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 0.6rem;
    cursor: pointer;
  }
  .login_form_button{
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 18rem;
    border: 1px #FD125A solid;
    color: #FD125A;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 0.6rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  .login_form_button:hover{
    background-color: #FD125A;
    color: #fff;
    transition: all 0.2s;
  }
  .login_form_button1:hover{
    opacity: 0.7;
    transition: all 0.2s;
  }
  .close_img{
    cursor: pointer;
    position: absolute;
    right: 0%;
    padding: 1rem;
  }
.data_operations{
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

@media(max-width:1200px)
{

  .window{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 0;
    top: 0;
   transform: translate(-0%, -0%);
    z-index: 30;
    background-color: white;
    padding: 20px 30px;
    width: 100%;
    left: 0;
    
  }
}

   
  
