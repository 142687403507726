.container{
    padding-bottom: 1rem;
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#fff;
}
.footer_inner{
    padding-top: 3rem;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr 1fr 1fr; 
}
.footer_btn{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background-color:#FD125A;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1.25rem;
}

.help{
    color: rgb(0, 0, 0);
    padding: 5px 15px;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition:  linear 0.25s;
    margin-right: 10px;
    font-size: 1.25rem;
}
.logo1 {
    font-style: italic;
    font-weight: 700;
    font-size: 36px;
    color: #FD125A;
}

.logo2 {
font-style: italic;
font-weight: 700;
font-size: 24px;
color: #000000;
}
.logo_inf{
    font-weight: 500;
    font-size: 0.7rem;
}

.footer_item{
   display: grid;   
}

.chz{
font-weight: 600;
font-size: 16px;


}
.chz_fake{
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}

.sale1{
    margin-right: 1rem;
}


.pl1{
    
   margin-right: 0.5rem;
}
.footer_btn1{
    display: none;
}
.footer_btn_right{
    display: none;
    margin: 2rem 0rem 2rem 0rem;
   
  
align-items: center;
justify-content: center;
color: #fff;
background-color:#FD125A;
border: none;
border-radius: 5px;
cursor: pointer;
padding: 0.7rem 1rem 0.7rem 1rem;
font-size: 1.25rem;
}
.cont{
    display: none;
    padding: 0rem 2rem 0rem 2rem;
}
.link{
    font-weight: 500;
    text-decoration: none;
    color: inherit;
}
.link:hover{
    color: #FD125A;
}
.rules{
    font-weight: 500;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
}
.rules:hover{
    
    color: #FD125A;
}

@media(max-width:1200px){
    .container{
        padding-bottom: 1rem;
        display: block;
        background-color:#fff;
    }
    .footer_item{
        display: block;
    }
    .cont{
        display: block;
        padding: 0rem 2rem 0rem 2rem;
    }
    .footer_inner{
        padding: 1rem;
        display: block;
    }
    .LOGO123{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
  
    .logo_inf{
        display: flex;
        justify-content: center;
        font-size: 0.7rem;
        font-weight: 600;
    }
    
    .footer_btn{
       display: none;
    }
    .cont{
        padding: 0rem 2rem 0rem 2rem;
    }
    .footer_btn_right{
        margin: 2rem 0rem 2rem 0rem;
        display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color:#FD125A;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1.25rem;
    }
    .chz{
        margin: 1rem 0rem 1rem 0rem;
    }
    .chz_fake{
        display: none;
    }
    
}

