.container{
    max-width: 1284px;
    margin: 0 auto;
}
.head{
    font-weight: 600;
    margin: 20px 0;
    color: #FD125A;
    font-size: 34px;
    text-align: center;
}
.item{
    margin-bottom: 15px;
}
.item_c1{
    margin-bottom: 15px;
    margin-left: 12px;
}
.item_c2{
    margin-bottom: 15px;
    margin-left: 24px;
}
.main{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}
.link{
    cursor: pointer;
    color: blue;
}
.link:hover{
    opacity: 0.7;
}
@media(max-width:1200px){
    .container{
        padding: 15px;
    }
}