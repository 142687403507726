.black_title{
    margin-bottom: 2rem;
  margin-top: 2rem;
    font-weight: 600;
    color: black;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
  }
  .password_input {
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid  #9e9d9d;
    outline: none;
    padding-left: 7px;
    width: 18rem;
    margin-bottom: 1rem;
    color: #FD125A;
  
  }
  .window{
  display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 1rem;
     left: 33%;
    top: 10%;
    z-index: 30;
    background-color: white;
    padding: 20px 100px;
  }
  .reg_title{
    font-weight: 600;
    font-size: 1.5rem;
    color: #FD125A;
    margin-bottom: 0.5rem;
    padding-top: 2rem;
   }
   .add_title{
    font-weight: 600;
    font-size: 1rem;
    color: #9e9d9d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login_form_button{
    margin-top: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FD125A;
    width: 18rem;
    border: none;
    color: white;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 0.6rem;
  }
  .close_img{
    cursor: pointer;
    position: absolute;
    right: 0%;
    padding: 1rem;
  }
  .modal_back{
    position:fixed;
    background-color: black;
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 20;
    
  }
  .login_form_button:hover{
    opacity: 0.5;
  }

  @media(max-width:1200px)
{

  .window{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 0;
   top: 0;
    z-index: 30;
    background-color: white;
    width: 100%;
    left: 0;
    
  }

}