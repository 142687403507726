.product_add{
    padding: 5%;
 
}
.footer_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background-color:#FD125A;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.7rem 1rem 0.7rem 1rem;
    font-size: 1.25rem;
    margin-bottom: 2rem;
}
.footer_btn:hover{
    opacity: 0.5;
}
.plus{
    margin-left: 2rem;
    font-size: 1.5rem;
}
.pagination{
    display: inline-block;
    margin: 15px auto 0;
}