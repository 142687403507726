.container{
    padding: 5%;
}
.pagination{
    background-color: #fff;
    padding: 5px;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}
.users_area{
width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5rem;

}

.input_item{
   
  
    border: none;
    outline: none;
    padding: 0.5rem;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    width: 20rem;
    
}
.loop{
    width: 2.5rem;
    height: 2.5rem;
    background-color: #FD125A;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
}
.users_title{
    font-weight: 600;
    font-size: 2rem;
    width: 40%;
}
.users_input{
    display: flex;
}
.find{
    background-color: #fff;
     width: 30%;
     
    height: 4vh;
    border: none;
    padding:1rem;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
     outline: none;
     background-color: white;
}