.about_items {

    margin-top: 3rem;
    justify-content: center;
    align-items: center;
    display: flex;
}

.about_item {
    background-color: #fff;
    height: 15vh;
    border: 1.23214px solid #FD125A;
    border-radius: 8.625px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    padding-left: 2rem;
}
.about_item2{
    background-color: #FD125A;
    height: 15vh;
    border: 1.23214px solid #FD125A;
    border-radius: 8.625px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    padding-left: 2rem;
}

.about_item2 .about_title{
    color: #fff;
}
.about_item2 .about_title_inf{
    color: #fff;
}

.about_title {
    font-family: "SF_Pro";
    font-weight: 600;
    font-size: 24.6429px;
    color: #FD125A;
}

.about_title_inf {
    font-family: "SF_Pro";
    font-weight: 500;
    font-size: 18.4821px;
    color: #C0C0C0;
}
.about_text{
    margin-right: 2rem;
}
.sup_text{
    rotate: 270deg;
width: 100%;
    color: #fff;
display: inline-block;
}

.sup{
    position: absolute;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: black;
    background-color: #FD125A;
    width: 3rem;
    height: 13rem;
    right:0%;
    top: 45%;
    padding: 2rem 0.5rem 3rem 0.5rem;
    border-radius: 0.5rem 0 0 0.5rem;
}
.link{
    text-decoration: none;
    color: inherit;
}
@media(max-width:1200px) {
   
    .about_items{
        display: none;
    }
    .sup{
        display: none;
        top: 13%;
       width: 2rem;
       height: 12rem;
       padding: 1.5rem 0.5rem 2rem 0.5rem;
    }
       
    
    }