.container{
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 1px #fff;
    padding: 9px;
    display: none;
}
.ctg_item{
    margin-top: 1.25rem;
    background-color: #fff;
    width: 80%;
    border-radius: 10px;
     font-weight: 600;
     font-size: 1.2rem;
     padding: 1rem 1rem 1rem 1rem;
    display: grid;
    grid-template-columns: 40% 15% 18% 20% 5%;
   align-items: center;


}
.footer_btn{
    cursor: pointer;
display: flex;
align-items: center;
justify-content: space-between;
color: #fff;
background-color:#FD125A;
border: none;
border-radius: 5px;
padding: 0.7rem 1rem 0.7rem 1rem;
font-size: 1.25rem;
}
.user_balance{
    margin-left: 5rem;
}
.container div{
    color: #fff;
}
.admin_users_header{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.admin_users_header{
    display: flex;
    justify-content: space-around;
}
.changeble_content{
    display: flex;
    align-items: center;
}
.user_status{
    color: #FD125A;
}

.changeble_content div{
    margin-right: 20px;
}
.locked_field{
    background-color: rgb(198, 81, 81);
}
.unlocked_field{
    background-color: rgb(59, 134, 59);
}
.name{
    margin-bottom: 5px;
}
.changeble_content{
    display: flex;
}
