*{
  font-family: "Gilroy";
}
.user_input {
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid  #9e9d9d;
  outline: none;
  padding-left: 7px;
  width: 18rem;
  margin-bottom: 1rem;
  color: #FD125A;
  font-family: "Gilroy";
}
.user_input::placeholder{
  color: #9e9d9d;
  font-weight: 400;
}

.user_input:focus{
  border: 1px solid  #FD125A;
  box-shadow: none;
}

.password_input::placeholder{
  color: #9e9d9d;
}


.window{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  border-radius: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  background-color: white;
  padding: 1.2rem 12rem;
  
}
.modal_back{
  position:fixed;
  background-color: black;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 20;
}


.buttons{
display: flex;
align-items: center;
justify-content: space-between;
}
.telegram{
display: flex;
align-items: center;
}
.reg_title{
  text-align: center;
font-weight: 600;
font-size: 1.5rem;
color: #FD125A;
margin-bottom: 0.5rem;
padding-top: 2rem;
}
.add_title{
  font-weight: 600;
font-size: 1rem;
color: #D7D7D7;
display: flex;
align-items: center;
justify-content: center;
}
.black_title{
margin-bottom: 0.7rem;
margin-top: 0.7rem;
font-weight: 600;
color: black;
font-size: 1.3rem;
display: flex;
justify-content: center;
}
.login_form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_form_button{
font-weight: 600;
display: flex;
justify-content: center;
align-items: center;
background-color: #fff;
width: 18rem;
border: 1px #FD125A solid;
color: #FD125A;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
border-radius: 0.3rem;
cursor: pointer;
margin-bottom: 0.7rem;
transition: all 0.2s;
}
.login_form_button:hover{
  color: #fff;
  background-color: #FD125A;
  transition: all 0.2s;
}
.login_form_button1{
font-weight: 600;
display: flex;
justify-content: center;
align-items: center;
background-color: #FD125A;
width: 18rem;
color: #fff;
border: none;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
border-radius: 0.3rem;
cursor: pointer;
margin-bottom: 0.7rem;
}
.login_form_button_cont{
background: none;
border: none;
outline: none;
margin-bottom: 0.6rem;
}
.add_title2{
  line-height: 13px;
 width: 300px;
color: #9e9d9d;
font-size: 11px;
text-align: center;
}
.login_form_button1:hover{
opacity: 0.7;
transition: all 0.1s;
}
.close_img{
  cursor: pointer;
position: absolute;
right: 0%;
padding: 1rem;
}

.aboba{
  padding: 0;
    width: 60%;
  text-align: center;
  font-size: 11px;
}

@media(max-width:1200px)
{

  .window{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 0;
   top: 0;
   transform: translate(-0%, -0%);
    z-index: 30;
    background-color: white;
    padding: 20px 30px;
    width: 100%;
    left: 0;
    
  }
}

   
  
