.container{
    max-width: 1284px;
    margin: 0 auto;
}
.head{
    font-weight: 600;
    margin: 20px 0;
    color: #FD125A;
    font-size: 34px;
    text-align: center;
}
ul{
    padding: 15px;
}
li{
    font-weight: 400;
    margin-bottom: 10px;
    
}
.main{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
}
.link{
    cursor: pointer;
    color: blue;
}
.link:hover{
    opacity: 0.7;
}
@media(max-width:1200px){
    .container{
        padding: 15px;
    }
    .main{
        text-align: center;
    }
}