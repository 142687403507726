
.attribute{
    margin-bottom: 5px;
}
.ctg_item{
    margin-top: 1.25rem;
    background-color: #fff;
    width: 70%;
    border-radius: 10px;
     font-weight: 600;
     font-size: 1.2rem;
     padding: 1rem 1rem 1rem 1rem;
    display: grid;
    grid-template-columns: 20% 10% 43% 20% 5%;
   align-items: center;

}
.edit{
    margin-left: 5rem;
    cursor: pointer;
}
.price_item{
    color: #FD125A;
}
.container{
   display: none;
}
.changeble_content{
    display: flex;
}
.locked_field{
    background-color: rgb(198, 81, 81);
}
.unlocked_field{
    background-color: rgb(59, 134, 59);
    margin-right: 5px;
}
.delete{
    width: 20px;
    
    cursor: pointer;
}